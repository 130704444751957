import {
  AppBar,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

export const HeaderRoot = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export const ToolBar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const Logo = styled("img")(({ theme }) => ({
  width: "100px",
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  color: "white",
}));

export const DrawerRoot = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.primary.dark,
    justifyContent: "flex-end",
    display: "flex",
    paddingTop: "4rem",
    "& a": {
      display: "flex",
      marginLeft: "1rem",
      marginRight: "1rem",
      width: "fit-content",
    },
  },
}));

interface IDrawerButton {
  active?: boolean;
}
export const DrawerButton = styled(Link, {
  shouldForwardProp: (prop) => prop !== "active",
})<IDrawerButton>(({ active, theme }) => ({
  color: "white",
  textDecoration: "none",
  textTransform: "uppercase",
  fontSize: "14px",
  padding: "1rem",
  fontFamily: "proxima-nova",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    paddingTop: "0.5rem",
    marginTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  },
  ...(active && {
    color: "white",
    textDecoration: "none",
    textTransform: "uppercase",
    fontSize: "14px",
    padding: "1rem",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    paddingTop: "0.5rem",
    marginTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  }),
}));

export const SignInButton = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  textTransform: "uppercase",
  padding: "1rem",
  fontSize: "14px",
  display: "flex",
  fontFamily: "proxima-nova",
  alignItems: "center",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    paddingTop: "0.5rem",
    marginTop: "0.5rem",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",
  },
  "& svg": {
    fontSize: "14px",
    marginLeft: "0.4rem",
  },
}));

export const LinkButtons = styled(Grid)(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  alignItems: "center",
}));
