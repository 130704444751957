import { Button, Grid, Typography, styled } from "@mui/material";

export const Logo = styled("img")(({ theme }) => ({
  width: "4rem",
  marginRight: "1rem",
}));

export const TitleRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const TextContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "left",
  },
}));

export const Badge = styled(Typography)(({ theme }) => ({
  backgroundColor: "#00527C",
  color: "white",
  borderRadius: "1rem",
  padding: "4px 16px",
  fontWeight: "normal",
  whiteSpace: "nowrap",
  width: "fit-content",
  height: "fit-content",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1rem",
  },
}));

interface IExampleImage {
  mobile?: boolean;
  direction: "horizontal" | "vertical";
}
export const ExampleImage = styled("img", {
  shouldForwardProp: (prop) => !["mobile", "direction"].some((x) => x === prop),
})<IExampleImage>(({ mobile, direction, theme }) => ({
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  [theme.breakpoints.down("md")]: {
    marginTop: "2rem",
  },
  ...(mobile && {
    marginTop: "1rem",
    width: "100%",
  }),
  ...(direction === "horizontal" && {
    width: "70%",
    maxWidth: "30rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
    },
  }),
  ...(direction === "vertical" && {
    width: "50%",
    maxWidth: "15rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
    },
  }),
}));

export const HorizontalImage = styled("img")(({ theme }) => ({
  width: "70%",
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
}));

export const VerticalImage = styled("img")(({ theme }) => ({
  width: "50%",
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
}));

export const TextSection = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: 1,
  gap: "0.4rem",
  maxWidth: "40rem",
  "& div": {
    display: "flex",
    gap: "12px",
  },
  [theme.breakpoints.up("md")]: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
}));

export const ContactButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "fit-content",
  "&:hover": {
    backgroundColor: "#26a6c9",
  },
}));

interface IImageSection {
  mobile?: boolean;
}
export const ImageSection = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "mobile",
})<IImageSection>(({ mobile, theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ...(mobile && {
    marginTop: "1rem",
  }),
}));

export const LinkButton = styled("a")(({ theme }) => ({
  textDecoration: "none",
}));
