import { Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LeftColumn } from "../../../Components/Columns/LeftColumn";
import { RightColumn } from "../../../Components/Columns/RightColumn";
import { HeroCard } from "../../../Components/HeroCard/HeroCard";
import { HeroSection } from "../../../Components/HeroSection/HeroSection";
import { SignatureCard } from "../../../Components/SignatureCard/SignatureCard";
import { Header, SubHeader } from "./StartHero.styles";
import { ContactButton } from "../../../Components/ContactButton/ContactButton";

export const StartHero: FC = () => {
  const intl = useIntl();
  return (
    <HeroSection
      bgPosition={"left bottom"}
      bgSize="100%"
      backgroundImage="Poly"
    >
      <LeftColumn justify="center">
        <HeroCard maxWidth="40rem" marginLeftAuto>
          <div>
            <Header variant="h1">
              {intl.formatMessage({ id: "start.topContainer.title.1" })}
              <span>
                {intl.formatMessage({ id: "start.topContainer.title.2" })}
              </span>
            </Header>
            <SubHeader variant="h2">
              {intl.formatMessage({ id: "start.topContainer.subtitle.1" })}
              <span>
                {intl.formatMessage({ id: "start.topContainer.subtitle.2" })}
              </span>
            </SubHeader>
          </div>
          <Typography>
            {intl.formatMessage({ id: "start.topContainer.description.1" })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: "start.topContainer.description.2" })}
          </Typography>
          <ContactButton />
        </HeroCard>
      </LeftColumn>
      <RightColumn justify="center">
        <SignatureCard />
      </RightColumn>
    </HeroSection>
  );
};
