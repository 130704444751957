import { Card, Typography, styled } from "@mui/material";

export const CardContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
}));

export const CardRoot = styled(Card)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: "1rem",
  },
  width: "70%",
  marginTop: "3rem",
  maxWidth: "30rem",
  "& .MuiCardHeader-title": {
    fontSize: "1.5rem",
  },
  "& .MuiCardHeader-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "nowrap",
    "span:last-child": {
      backgroundColor: "#00527C",
      color: "white",
      borderRadius: "4px",
      padding: "4px 8px",
      fontWeight: "normal",
      whiteSpace: "nowrap",
    },
  },
  boxShadow:
    "-10px -10px 0 2px rgb(255,235,153), -20px -20px 0 2px rgb(255,206,0), 0 7px 30px -10px rgba(150,170,180,0.5)",
}));

export const Logo = styled("img")(({ theme }) => ({
  width: "40px",
}));

export const BottomIconContainer = styled("div")(({ theme }) => ({
  marginTop: "2rem",
  display: "flex",
  justifyContent: "center",
}));

export const List = styled("ul")(({ theme }) => ({
  paddingLeft: "1rem",
}));

export const LogoTitle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.only("md")]: {
    marginTop: "1rem",
  },
}));

export const Badge = styled(Typography)(({ theme }) => ({
  backgroundColor: "#00527C",
  color: "white",
  borderRadius: "1rem",
  padding: "4px 16px",
  fontWeight: "normal",
  whiteSpace: "nowrap",
  width: "fit-content",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1rem",
  },
}));
