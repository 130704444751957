import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LeftColumn } from "../../../Components/Columns/LeftColumn";
import { RightColumn } from "../../../Components/Columns/RightColumn";
import { HeroCard } from "../../../Components/HeroCard/HeroCard";
import { HeroSection } from "../../../Components/HeroSection/HeroSection";
import { ImageContainer } from "../../../Components/HeroSection/HeroSection.styles";
import { ReactComponent as CloudsColored } from "../../../Images/clouds_colored.svg";
import { ContactButton } from "../../../Components/ContactButton/ContactButton";

export const ProductsHero: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <HeroSection
      bgPosition={isMobile ? "100% 20%" : "right bottom"}
      bgSize="100%"
      backgroundImage="PeaksFull"
    >
      <LeftColumn gap="1rem">
        <HeroCard maxWidth="40rem">
          <Typography variant="h1" fontWeight="bolder">
            {intl.formatMessage({
              id: "products.info.title",
            })}
          </Typography>
          <Typography fontWeight="bold">
            {intl.formatMessage({
              id: "products.info.subtitle",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "products.info.description.1",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "products.info.description.2",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "products.info.description.3",
            })}
          </Typography>
          <Typography fontStyle="italic">
            {intl.formatMessage({
              id: "products.info.description.4",
            })}
          </Typography>
          <ContactButton />
        </HeroCard>
      </LeftColumn>
      <RightColumn>
        <ImageContainer>
          <CloudsColored />
        </ImageContainer>
      </RightColumn>
    </HeroSection>
  );
};
