import { Card, CardActions, Grid, Typography, styled } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { HashLink } from "react-router-hash-link";
import PurpleBlob from "../../Images/blob_purple.svg";
import RedBlob from "../../Images/blob_red.svg";
import YellowBlob from "../../Images/blob_yellow.svg";
import OrangeBlob from "../../Images/blob_orange.svg";

export const CardsContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "-3rem",
}));

export const HandheldRoot = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const StyledCarousel = styled(Carousel)(({ theme }) => ({
  width: "100%",
  "& > div > div": {
    display: "flex",
    justifyContent: "center",
    "& > div > div > div > div": {
      maxWidth: "unset",
    },
  },
}));

export const CardContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "4rem",
  padding: "1rem",
  height: "17rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "19rem",
  },
}));

interface ICardRoot {
  shadowColor: "yellow" | "red" | "purple" | "orange";
}
export const CardRoot = styled(Card, {
  shouldForwardProp: (prop) => prop !== "shadowColor",
})<ICardRoot>(({ shadowColor, theme }) => ({
  padding: "2rem",
  fontFamily: "proxima-nova",
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderRadius: "0.5rem",
  width: "100%",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "20rem",
  },
  gap: "1rem",
  ...(shadowColor === "yellow" && {
    backgroundImage: "url(" + YellowBlob + ")",
  }),
  ...(shadowColor === "red" && {
    backgroundImage: "url(" + RedBlob + ")",
  }),
  ...(shadowColor === "purple" && {
    backgroundImage: "url(" + PurpleBlob + ")",
  }),
  ...(shadowColor === "orange" && {
    backgroundImage: "url(" + OrangeBlob + ")",
  }),
}));

export const Header = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
}));

export const Content = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
}));

export const Actions = styled(CardActions)(({ theme }) => ({
  marginTop: "auto",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginLeft: "1rem",
}));

export const ProductLogo = styled("img")(({ theme }) => ({
  width: "40px",
}));

interface IReadMoreButton {
  color: "yellow" | "red" | "purple" | "orange";
}
export const ReadMoreButton = styled(HashLink, {
  shouldForwardProp: (prop) => prop !== "color",
})<IReadMoreButton>(({ color, theme }) => ({
  textDecoration: "none",
  marginLeft: "auto",
  "& button": {
    color: "black",
    "& svg": {
      marginLeft: "0.5rem",
      fontSize: "1rem",
    },
    ...(color === "yellow" && {
      background: "#FFDD4D",
      "&:hover": {
        background: "#f6d955",
      },
    }),
    ...(color === "red" && {
      background: "#FF8369",
      "&:hover": {
        background: "#f08e7a",
      },
    }),
    ...(color === "purple" && {
      background: "#DBA2D4",
      "&:hover": {
        background: "#d5aad0",
      },
    }),
    ...(color === "orange" && {
      background: "#FF9100",
      "&:hover": {
        background: "#ffa229",
      },
    }),
  },
}));
