import { OpenInNew } from "@mui/icons-material";
import { Button, styled } from "@mui/material";

export const Content = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "fit-content",
  "&:hover": {
    backgroundColor: "#26a6c9",
  },
}));

export const OpenInNewIcon = styled(OpenInNew)(({ theme }) => ({
  marginLeft: "0.5rem",
  fontSize: "16px",
}));

export const ContactButtonRoot = styled("a")(({ theme }) => ({
  textDecoration: "none",
}));
