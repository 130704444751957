import { Grid, Table, TableCell, Typography, styled } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Mountains from "../../Images/mountains.svg";

export const Root = styled("div")(({ theme }) => ({
  backgroundColor: "#15191F",
  backgroundImage: "url(" + Mountains + ")",
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  height: "auto",
  color: "white",
  flexGrow: 1,
  "& p": {
    color: "white",
  },
  "& > div": {
    [theme.breakpoints.only("xs")]: {
      gap: "2rem",
    },
  },
  "& a,button": {
    width: "fit-content",
    textDecoration: "none",
  },
}));

export const TextContainer = styled(Typography)(({ theme }) => ({
  whiteSpace: "pre-line",
  "& span": {
    color: theme.palette.primary.dark,
    backgroundColor: "white",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
}));

export const LinkGrid = styled(Grid)(({ theme }) => ({
  "& a": {
    color: "black",
  },
}));

export const List = styled("ul")(({ theme }) => ({
  marginTop: 0,
  paddingLeft: "1rem",
  marginBottom: 0,
}));

export const InnerList = styled("ul")(({ theme }) => ({
  marginTop: 0,
  paddingLeft: "1rem",
  fontFamily: "proxima-nova",
  "li:first-child": {
    marginTop: "0.5rem",
  },
  "& li": {
    marginBottom: "0.5rem",
  },
}));

export const NumberList = styled("ol")(({ theme }) => ({
  paddingLeft: "1rem",
  fontFamily: "proxima-nova",
  "& li": {
    marginBottom: "1rem",
  },
}));

interface IMobileHeaderCell {
  position?: "left" | "right";
}
export const MobileHeaderCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "position",
})<IMobileHeaderCell>(({ position, theme }) => ({
  ...(position === "left" && {
    borderRight: "none",
  }),
  ...(position === "right" && {
    borderLeft: "none",
  }),
}));

export const ButtonContainer = styled(Grid)(({ theme }) => ({}));

export const LinkButton = styled("a")(({ theme }) => ({
  backgroundColor: "#E9EFF3",
  color: "black",
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  justifyContent: "space-between",
  borderRadius: "8px",
  padding: "8px 16px",
  textDecoration: "none",
  "&:hover": {
    backgroundColor: "#d3dee6",
  },
}));

export const ButtonText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTable-root": {
    borderCollapse: "unset",
    borderRadius: "1rem",
  },
  "& .MuiTableCell-head": {
    "&:first-child": {
      borderTopLeftRadius: "0.5rem",
    },
    "&:last-child": {
      borderTopRightRadius: "0.5rem",
    },
  },
  "& .MuiTableRow-root:last-child": {
    "& .MuiTableCell-body": {
      "&:first-child": {
        borderBottomLeftRadius: "0.5rem",
      },
      "&:last-child": {
        borderBottomRightRadius: "0.5rem",
      },
    },
  },
  borderCollapse: "unset",
  borderRadius: "10rem",
}));

export const StyledMobileTable = styled(Table)(({ theme }) => ({
  "& .MuiTable-root": {
    borderCollapse: "unset",
    borderRadius: "1rem",
  },

  "& .MuiTableHead-root:first-child": {
    "& .MuiTableCell-head": {
      "&:first-child": {
        borderTopLeftRadius: "0.5rem",
      },
      "&:last-child": {
        borderTopRightRadius: "0.5rem",
      },
    },
  },
  "& .MuiTableBody-root:last-child": {
    "& .MuiTableRow-root:last-child": {
      "& .MuiTableCell-body": {
        "&:first-child": {
          borderBottomLeftRadius: "0.5rem",
        },
        "&:last-child": {
          borderBottomRightRadius: "0.5rem",
        },
      },
    },
  },

  borderCollapse: "unset",
  borderRadius: "10rem",
}));

export const StyledText = styled(FormattedMessage)(({ theme }) => ({
  fontFamily: "proxima-nova",
  color: "red",
}));
