import { styled } from "@mui/material";

export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "2rem",
  [theme.breakpoints.up("md")]: {
    padding: "4rem",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "2rem 10rem",
  },
}));
