import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import App from "./App";
import messages_en from "./Translations/en.json";
import messages_sv from "./Translations/sv.json";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const messages = {
  sv: messages_sv,
  en: messages_en,
};

// get browser language without the region code
const language = navigator.language.split(/[-_]/)[0];

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <IntlProvider
      locale="sv"
      defaultLocale="sv"
      messages={(messages as any)[language]}
    >
      <App />
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
