import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import QuickpartsLogo from "../../Images/quickparts_logo.svg";
import SignatureLogo from "../../Images/signature_logo.svg";
import TemplateLogo from "../../Images/template_logo.svg";
import ContentLogo from "../../Images/content_logo.svg";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Actions,
  CardContainer,
  CardRoot,
  CardsContainer,
  Content,
  HandheldRoot,
  Header,
  ProductLogo,
  ReadMoreButton,
  StyledCarousel,
  Title,
} from "./ProductCards.styles";

export const ProductCards: FC = () => {
  const theme = useTheme();
  const isHandheld = useMediaQuery(theme.breakpoints.down("lg"));
  const intl = useIntl();
  const data: any[] = [
    {
      title: intl.formatMessage({
        id: "start.middleContainer.signatureCard.title",
      }),
      description: intl.formatMessage({
        id: "start.middleContainer.signatureCard.description",
      }),
      logo: SignatureLogo,
      color: "#FFCE00",
      shadow: "yellow",
      link: "/products#signature",
      buttonText: "button.readMore.signature",
    },
    {
      title: intl.formatMessage({
        id: "start.middleContainer.templateCard.title",
      }),
      description: intl.formatMessage({
        id: "start.middleContainer.templateCard.description",
      }),
      logo: TemplateLogo,
      color: "#FF6D4F",
      shadow: "red",
      link: "/products#template",
      buttonText: "button.readMore.template",
    },
    {
      title: intl.formatMessage({
        id: "start.middleContainer.quickpartsCard.title",
      }),
      description: intl.formatMessage({
        id: "start.middleContainer.quickpartsCard.description",
      }),
      logo: QuickpartsLogo,
      color: "#DB8CD1",
      shadow: "purple",
      link: "/products#quickparts",
      buttonText: "button.readMore.quickparts",
    },
    /* {
      title: intl.formatMessage({
        id: "start.middleContainer.contentSelectorCard.title",
      }),
      description: intl.formatMessage({
        id: "start.middleContainer.contentSelectorCard.description",
      }),
      logo: ContentLogo,
      color: "#DB8CD1",
      shadow: "orange",
      link: "/products#contentselector",
      buttonText: "button.readMore.contentSelector",
    }, */
  ];

  return (
    <CardsContainer container>
      {isHandheld && (
        <HandheldRoot>
          <StyledCarousel
            autoPlay={false}
            animation="fade"
            navButtonsAlwaysInvisible
            cycleNavigation={true}
            indicatorIconButtonProps={{
              style: {
                color: theme.palette.primary.light,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: theme.palette.primary.dark,
              },
            }}
            indicatorContainerProps={{
              style: {
                marginTop: "5rem",
              },
            }}
          >
            {data.map((x) => (
              <>
                <CardContainer item xs={12} md={4}>
                  <CardRoot shadowColor={x.shadow}>
                    <Header>
                      <ProductLogo src={x.logo} alt={`${x.title} logo`} />
                      <Title variant="h3">{x.title}</Title>
                    </Header>
                    <Content>
                      <Typography>{x.description}</Typography>
                    </Content>
                    <Actions>
                      <ReadMoreButton to={x.link} color={x.shadow}>
                        <Button>
                          {intl.formatMessage({
                            id: x.buttonText,
                          })}
                          <ArrowForwardIcon />
                        </Button>
                      </ReadMoreButton>
                    </Actions>
                  </CardRoot>
                </CardContainer>
              </>
            ))}
          </StyledCarousel>
        </HandheldRoot>
      )}
      {!isHandheld && (
        <>
          {data.map((x) => (
            <>
              <CardContainer item xs={12} md={4} xl={3}>
                <CardRoot shadowColor={x.shadow}>
                  <Header>
                    <ProductLogo src={x.logo} alt={`${x.title} logo`} />
                    <Title variant="h3">{x.title}</Title>
                  </Header>
                  <Content>
                    <Typography>{x.description}</Typography>
                  </Content>
                  <Actions>
                    <ReadMoreButton to={x.link} color={x.shadow}>
                      <Button>
                        {intl.formatMessage({
                          id: x.buttonText,
                        })}
                        <ArrowForwardIcon />
                      </Button>
                    </ReadMoreButton>
                  </Actions>
                </CardRoot>
              </CardContainer>
            </>
          ))}
        </>
      )}
    </CardsContainer>
  );
};
