import { Grid, styled } from "@mui/material";
import Island from "../../Images/island_2.svg";
import Peaks from "../../Images/peaks.svg";
import Poly from "../../Images/poly.svg";
import HorizontalPeaks from "../../Images/horizontal_peaks.svg";
import PeaksFull from "../../Images/peaks_full.svg";
import PeaksHorizontal from "../../Images/horizontal_peaks.svg";
import PeaksMobile from "../../Images/peaks_mobile.svg";
import Waves from "../../Images/peaks_multi.svg";

interface IRoot {
  bgPosition: string;
  bgSize?: string;
  paddingBottom?: string;
  transform?: string;
  backgroundImage?:
    | "Island"
    | "Peaks"
    | "HorizontalPeaks"
    | "Poly"
    | "PeaksHorizontal"
    | "PeaksFull"
    | "PeaksMobile"
    | "Waves";
}
export const Root = styled(Grid, {
  shouldForwardProp: (prop) =>
    ![
      "bgPosition",
      "bgSize",
      "paddingBottom",
      "backgroundImage",
      "transform",
    ].some((x) => x === prop),
})<IRoot>(
  ({
    bgPosition,
    bgSize,
    paddingBottom,
    backgroundImage,
    transform,
    theme,
  }) => ({
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    color: "white",
    backgroundPosition: bgPosition,
    backgroundRepeat: "no-repeat",
    backgroundSize: bgSize ? bgSize : "70%",

    ...(backgroundImage === "Island" && {
      backgroundImage: "url(" + Island + ")",
    }),
    ...(backgroundImage === "Peaks" && {
      backgroundImage: "url(" + Peaks + ")",
    }),
    ...(backgroundImage === "HorizontalPeaks" && {
      backgroundImage: "url(" + HorizontalPeaks + ")",
    }),
    ...(backgroundImage === "Poly" && {
      backgroundImage: "url(" + Poly + ")",
      backgroundRepeat: "repeat",
    }),
    ...(backgroundImage === "PeaksHorizontal" && {
      backgroundImage: "url(" + PeaksHorizontal + ")",
    }),
    ...(backgroundImage === "PeaksFull" && {
      backgroundImage: "url(" + PeaksFull + ")",
    }),
    ...(backgroundImage === "PeaksMobile" && {
      backgroundImage: "url(" + PeaksMobile + ")",
    }),
    ...(backgroundImage === "Waves" && {
      backgroundImage: "url(" + Waves + ")",
      backgroundRepeat: "repeat",
    }),
    [theme.breakpoints.down("md")]: {
      backgroundSize: "100%",
    },
    padding: "4rem",
    paddingBottom: paddingBottom,
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem 4rem 2rem",
      paddingBottom: paddingBottom,
    },
    [theme.breakpoints.up("xl")]: {
      padding: "2rem 10rem",
      paddingBottom: paddingBottom,
    },
    "& .MuiGrid-item": {
      paddingLeft: 0,
    },
  })
);

export const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& svg": {
    width: "70%",
  },
}));
