import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ContactButton } from "../../Components/ContactButton/ContactButton";
import { HeroCard } from "../../Components/HeroCard/HeroCard";
import { HeroSection } from "../../Components/HeroSection/HeroSection";
import { TextContainer } from "./Accessibility.styles";

export const AccessibilityHero: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <HeroSection
      bgPosition="right bottom"
      bgSize={isLargeScreen ? "70%" : "100%"}
      backgroundImage={isMobile ? "PeaksMobile" : "HorizontalPeaks"}
      alignStart
    >
      <Grid
        xl={6}
        container
        justifyContent={isXLargeScreen ? "flex-end" : "flex-start"}
        pr={isXLargeScreen ? "2rem" : ""}
      >
        <HeroCard maxWidth="40rem">
          <Typography variant="h1">
            {intl.formatMessage({ id: "header.accessibility" })}
          </Typography>
          <TextContainer variant="h2">
            <FormattedMessage
              id="accessibility.info.1"
              values={{
                span: (chunks) => <span>{chunks}</span>,
              }}
            />
          </TextContainer>
          <Typography>
            {intl.formatMessage({ id: "accessibility.info.2" })}
          </Typography>
          <TextContainer>
            <FormattedMessage
              id="accessibility.info.3"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </TextContainer>
          <ContactButton />
        </HeroCard>
      </Grid>
    </HeroSection>
  );
};
