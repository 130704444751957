import { Grid } from "@mui/material";
import { FC } from "react";
import { SignatureContainer } from "../Products/SignatureContainer/SignatureContainer";

export const Signature365: FC = () => {
  return (
    <Grid>
      <SignatureContainer />
    </Grid>
  );
};
