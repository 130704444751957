import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import SimpledocLogo from "../../Images/simpledoc_blue_logo.svg";
import {
  DrawerButton,
  DrawerRoot,
  HeaderRoot,
  LinkButtons,
  Logo,
  MenuButton,
  SignInButton,
  ToolBar,
} from "./Header.styles";
import { OpenInNew } from "@mui/icons-material";
type Anchor = "top";

export const Header: FC = () => {
  const location = useLocation();

  const checkActiveButton = (path: string) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = React.useState({
    top: false,
  });

  const [toggleIcon, setToggleIcon] = React.useState(false);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setToggleIcon(!toggleIcon);
      setState({ ...state, [anchor]: open });
    };
  const intl = useIntl();

  return (
    <HeaderRoot position="sticky">
      <ToolBar>
        <Grid>
          <Link to="/">
            <Logo src={SimpledocLogo} alt="Simpledoc logo" />
          </Link>
        </Grid>
        {isMobile && (
          <div>
            {(["top"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                {!toggleIcon && (
                  <MenuButton onClick={toggleDrawer(anchor, true)}>
                    <MenuIcon />
                  </MenuButton>
                )}
                {toggleIcon && (
                  <MenuButton onClick={toggleDrawer(anchor, false)}>
                    <CloseIcon />
                  </MenuButton>
                )}

                <DrawerRoot
                  keepMounted
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  <DrawerButton
                    to="/products"
                    onClick={toggleDrawer(anchor, false)}
                    active={checkActiveButton("/products")}
                  >
                    {intl.formatMessage({ id: "header.products" })}
                  </DrawerButton>
                  <DrawerButton
                    to="/services"
                    onClick={toggleDrawer(anchor, false)}
                    active={checkActiveButton("/services")}
                  >
                    {intl.formatMessage({ id: "header.services" })}
                  </DrawerButton>
                  <DrawerButton
                    to="/accessibility"
                    onClick={toggleDrawer(anchor, false)}
                    active={checkActiveButton("/accessibility")}
                  >
                    {intl.formatMessage({ id: "header.accessibility" })}
                  </DrawerButton>
                  <DrawerButton
                    to="/contact"
                    onClick={toggleDrawer(anchor, false)}
                    active={checkActiveButton("/contact")}
                  >
                    {intl.formatMessage({ id: "header.contact" })}
                  </DrawerButton>
                  {/*    <DrawerButton
                    to="/about"
                    onClick={toggleDrawer(anchor, false)}
                    active={checkActiveButton("/about")}
                  >
                    {intl.formatMessage({ id: "header.about" })}
                  </DrawerButton> */}
                  <SignInButton
                    href="https://simpledoc.online/"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    {intl.formatMessage({ id: "header.signIn" })}
                  </SignInButton>
                </DrawerRoot>
              </React.Fragment>
            ))}
          </div>
        )}
        {!isMobile && (
          <LinkButtons>
            <DrawerButton
              to="/products"
              active={checkActiveButton("/products")}
            >
              {intl.formatMessage({ id: "header.products" })}
            </DrawerButton>
            <DrawerButton
              to="/services"
              active={checkActiveButton("/services")}
            >
              {intl.formatMessage({ id: "header.services" })}
            </DrawerButton>
            <DrawerButton
              to="/accessibility"
              active={checkActiveButton("/accessibility")}
            >
              {intl.formatMessage({ id: "header.accessibility" })}
            </DrawerButton>

            <DrawerButton to="/contact" active={checkActiveButton("/contact")}>
              {intl.formatMessage({ id: "header.contact" })}
            </DrawerButton>
            {/*    <DrawerButton to="/about" active={checkActiveButton("/about")}>
              {intl.formatMessage({ id: "header.about" })}
            </DrawerButton> */}
            <IconButton>
              <SignInButton href="https://simpledoc.online/" target="blank">
                {intl.formatMessage({ id: "header.signIn" })}
                <OpenInNew />
              </SignInButton>
            </IconButton>
          </LinkButtons>
        )}
      </ToolBar>
    </HeaderRoot>
  );
};
