import { Grid, styled } from "@mui/material";
import Mountains from "../../Images/mountains.svg";
import CloudsDeviceBlue from "../../Images/clouds_device_blue.svg";
import CloudsDeviceWhite from "../../Images/clouds_device_white.svg";
import DevicesCircle from "../../Images/devices_circle.svg";
import Devices from "../../Images/devices.svg";
import Island from "../../Images/island.svg";
import YellowBlob from "../../Images/blob_yellow_half.svg";
import RedBlob from "../../Images/blob_red_half.svg";
import PurpleBlob from "../../Images/blob_purple_half.svg";
import OrangeBlob from "../../Images/blob_orange_half.svg";

interface ISectionRoot {
  blueBackground?: boolean;
  blackBackground?: boolean;
  column?: boolean;
  mountains?: boolean;
  cloudsDeviceBlue?: boolean;
  cloudsDeviceWhite?: boolean;
  devicesCircle?: boolean;
  devices?: boolean;
  bgPosition?: string;
  bgSize?: string;
  removeBottomPadding?: boolean;
  backgroundImage?:
    | "Mountains"
    | "CloudsDeviceBlue"
    | "CloudsDeviceWhite"
    | "DevicesCircle"
    | "Island"
    | "YellowBlob"
    | "RedBlob"
    | "PurpleBlob"
    | "OrangeBlob"
    | "Devices";
}
export const SectionRoot = styled(Grid, {
  shouldForwardProp: (prop) =>
    ![
      "blueBackground",
      "blackBackground",
      "column",
      "mountains",
      "cloudsDeviceBlue",
      "cloudsDeviceWhite",
      "devicesCircle",
      "devices",
      "bgPosition",
      "bgSize",
      "removeBottomPadding",
      "backgroundImage",
    ].some((x) => x === prop),
})<ISectionRoot>(
  ({
    blueBackground,
    blackBackground,
    column,
    mountains,
    cloudsDeviceBlue,
    cloudsDeviceWhite,
    devicesCircle,
    devices,
    bgPosition,
    bgSize,
    removeBottomPadding,
    backgroundImage,
    theme,
  }) => ({
    display: "flex",
    scrollMarginTop: "2rem",
    [theme.breakpoints.up("md")]: {
      flexWrap: "nowrap",
      gap: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      backgroundSize: "100%",
      paddingBottom: removeBottomPadding ? 0 : "",
    },
    padding: "4rem",
    [theme.breakpoints.down("sm")]: {
      padding: "4rem 2rem",
      paddingBottom: removeBottomPadding ? 0 : "",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "6rem 10rem",
      paddingBottom: removeBottomPadding ? 0 : "",
    },
    "& .MuiGrid-item": {
      paddingLeft: 0,
    },
    ...(blueBackground && {
      background: theme.palette.primary.light,
    }),
    ...(blackBackground && {
      backgroundColor: "#222224",
      color: "#ededed",
    }),
    backgroundPosition: bgPosition ? bgPosition : "right bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: bgSize ? bgSize : "50%",
    ...(backgroundImage === "Mountains" && {
      backgroundImage: "url(" + Mountains + ")",
    }),
    ...(backgroundImage === "CloudsDeviceBlue" && {
      backgroundImage: "url(" + CloudsDeviceBlue + ")",
    }),
    ...(backgroundImage === "CloudsDeviceWhite" && {
      backgroundImage: "url(" + CloudsDeviceWhite + ")",
    }),
    ...(backgroundImage === "DevicesCircle" && {
      backgroundImage: "url(" + DevicesCircle + ")",
    }),
    ...(backgroundImage === "Island" && {
      backgroundImage: "url(" + Island + ")",
    }),
    ...(backgroundImage === "Devices" && {
      backgroundImage: "url(" + Devices + ")",
    }),
    ...(backgroundImage === "YellowBlob" && {
      backgroundImage: "url(" + YellowBlob + ")",
      backgroundPosition: "bottom right",
    }),
    ...(backgroundImage === "RedBlob" && {
      backgroundImage: "url(" + RedBlob + ")",
      backgroundPosition: "bottom left",
    }),
    ...(backgroundImage === "PurpleBlob" && {
      backgroundImage: "url(" + PurpleBlob + ")",
      backgroundSize: "30%",
      backgroundPosition: "bottom right",
    }),
    ...(backgroundImage === "OrangeBlob" && {
      backgroundImage: "url(" + OrangeBlob + ")",
      backgroundPosition: "bottom left",
    }),
    ...(removeBottomPadding && {
      paddingBottom: 0,
    }),
    ...(column && {
      flexDirection: "column",
    }),
  })
);
