import { Grid } from "@mui/material";
import { FC } from "react";
import { ProductsHero } from "./ProductsHero/ProductsHero";
import { QuickpartsContainer } from "./QuickpartsContainer/QuickpartsContainer";
import { SignatureContainer } from "./SignatureContainer/SignatureContainer";
import { TemplateContainer } from "./TemplateContainer/TemplateContainer";
import { ContentSelectorContainer } from "./ContentSelectorContainer/ContentSelectorContainer";

export const Products: FC = () => {
  return (
    <Grid>
      <ProductsHero />
      <SignatureContainer />
      <TemplateContainer />
      <QuickpartsContainer />
      {/* <ContentSelectorContainer /> */}
    </Grid>
  );
};
