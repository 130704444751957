import { FC } from "react";
import { Right } from "./Columns.styles";

interface IProps {
  children: any;
  gap?: string;
  justify?: string;
  align?: string;
}

export const RightColumn: FC<IProps> = (props) => {
  return (
    <Right
      item
      xs={12}
      md={6}
      xl={4}
      gap={props.gap}
      justifyContent={props.justify}
      alignItems={props.align}
    >
      {props.children}
    </Right>
  );
};
