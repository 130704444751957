import { FC } from "react";
import { SectionRoot } from "./Section.styles";

interface IProps {
  children: any;
  blueBackground?: boolean;
  blackBackground?: boolean;
  column?: boolean;
  mountains?: boolean;
  cloudsDeviceBlue?: boolean;
  cloudsDeviceWhite?: boolean;
  devicesCircle?: boolean;
  devices?: boolean;
  gap?: string;
  bgPosition?: string;
  bgSize?: string;
  bgOpacity?: string;
  removeBottomPadding?: boolean;
  id?: string;
  justifyContent?: string;
  backgroundImage?:
    | "Mountains"
    | "CloudsDeviceBlue"
    | "CloudsDeviceWhite"
    | "DevicesCircle"
    | "Island"
    | "YellowBlob"
    | "RedBlob"
    | "PurpleBlob"
    | "OrangeBlob"
    | "Devices";
}

export const Section: FC<IProps> = (props) => {
  return (
    <SectionRoot
      blueBackground={props.blueBackground}
      blackBackground={props.blackBackground}
      column={props.column}
      mountains={props.mountains}
      cloudsDeviceBlue={props.cloudsDeviceBlue}
      cloudsDeviceWhite={props.cloudsDeviceWhite}
      devicesCircle={props.devicesCircle}
      devices={props.devices}
      bgPosition={props.bgPosition}
      bgSize={props.bgSize}
      container
      gap={props.gap}
      removeBottomPadding={props.removeBottomPadding}
      backgroundImage={props.backgroundImage}
      id={props.id}
      justifyContent={props.justifyContent ? props.justifyContent : "center"}
    >
      {props.children}
    </SectionRoot>
  );
};
