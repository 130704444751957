import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LeftColumn } from "../../../Components/Columns/LeftColumn";
import { RightColumn } from "../../../Components/Columns/RightColumn";
import { HeroCard } from "../../../Components/HeroCard/HeroCard";
import { HeroSection } from "../../../Components/HeroSection/HeroSection";
import { ReactComponent as DevicesColored } from "../../../Images/devices_colored.svg";
import { ImageContainer } from "../../../Components/HeroSection/HeroSection.styles";

export const ServicesHero: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <HeroSection
      bgPosition={isMobile ? "bottom" : "50% 100%"}
      bgSize="100%"
      backgroundImage="Peaks"
    >
      <LeftColumn gap={"1rem"}>
        <HeroCard maxWidth="40rem">
          <Typography variant="h1">
            {intl.formatMessage({
              id: "header.services",
            })}
          </Typography>

          <Typography fontWeight="bold">
            {intl.formatMessage({
              id: "services.hero.1",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "services.hero.2",
            })}
          </Typography>
        </HeroCard>
      </LeftColumn>
      <RightColumn>
        <ImageContainer>
          <DevicesColored />
        </ImageContainer>
      </RightColumn>
    </HeroSection>
  );
};
