import { Grid, styled } from "@mui/material";

export const Root = styled(Grid)(({ theme }) => ({
  backgroundColor: "#161616",
  color: "white",
}));

export const TitleRow = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  marginBottom: "1rem",
}));

export const SimpledocLogo = styled("img")(({ theme }) => ({
  width: "10rem",
}));

export const LinkedinLogo = styled("img")(({ theme }) => ({
  width: "2rem",
}));

export const ExternalLink = styled("a")(({ theme }) => ({
  color: "white",
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}));

export const LinkContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  lineHeight: 1.5,
}));
