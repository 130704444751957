import { FC } from "react";
import { ContainerRoot } from "./Container.styles";

interface IProps {
  children: any;
  direction?: "row" | "column";
  justifyContent?: "center" | "flex-end" | "flex-start" | "space-between";
  alignItems?: "center" | "flex-end" | "flex-start" | "space-between";
  marginTop?: string;
  marginBottom?: string;
}

export const Container: FC<IProps> = (props) => {
  return (
    <ContainerRoot
      container
      direction={props.direction}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
    >
      {props.children}
    </ContainerRoot>
  );
};
