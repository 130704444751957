import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LeftColumn } from "../../../Components/Columns/LeftColumn";
import { RightColumn } from "../../../Components/Columns/RightColumn";
import { Section } from "../../../Components/Section/Section";
import Computer from "../../../Images/computer.svg";
import Microsoft365Logo from "../../../Images/microsoft-365.svg";
import MicrosoftOfficeLogo from "../../../Images/microsoft_office_logo.svg";
import OneDriveLogo from "../../../Images/onedrive.svg";
import SharePointLogo from "../../../Images/sharepoint_logo.svg";
import TeamsLogo from "../../../Images/teams_logo.svg";
import {
  ComputerImage,
  IconsContainer,
  LinkButton,
} from "./ServicesInfo.styles";
export const ServicesInfo: FC = () => {
  const intl = useIntl();
  return (
    <>
      <Section>
        <LeftColumn gap="1rem">
          <Typography variant="h2" fontWeight={"bold"}>
            {intl.formatMessage({
              id: "services.hero.4",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "services.hero.5",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "services.hero.6",
            })}
          </Typography>
        </LeftColumn>
        <RightColumn justify="center" align="center">
          <ComputerImage width={"50%"} src={Computer} alt={"Phone logo"} />
        </RightColumn>
      </Section>
      <Section blueBackground removeBottomPadding>
        <LeftColumn gap="1rem">
          <Typography variant="h2">
            {intl.formatMessage({
              id: "services.info.1",
            })}
          </Typography>
          <Typography>
            {intl.formatMessage({
              id: "services.info.2",
            })}
          </Typography>
        </LeftColumn>
        <RightColumn>
          <IconsContainer>
            <img src={Microsoft365Logo} alt={"Microsoft 365 logo"} />
            <img src={MicrosoftOfficeLogo} alt={"Microsoft 365 logo"} />
            <img src={TeamsLogo} alt={"Microsoft logo"} />
            <img src={OneDriveLogo} alt={"OneDrive logo"} />
            <img src={SharePointLogo} alt={"SharePoint logo"} />
          </IconsContainer>
        </RightColumn>
      </Section>
      <Section blueBackground>
        <LeftColumn gap="1rem">
          <Grid>
            <Typography variant="h2">
              {intl.formatMessage({
                id: "services.info.3",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.4",
              })}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "services.info.5",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.6",
              })}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "services.info.7",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.8",
              })}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "services.info.9",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.10",
              })}
            </Typography>
          </Grid>
        </LeftColumn>
        <RightColumn gap="1rem">
          <Grid>
            {" "}
            <Typography variant="body2">
              {intl.formatMessage({
                id: "services.info.11",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.12",
              })}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "services.info.13",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "services.info.14",
              })}
            </Typography>
          </Grid>
          <LinkButton to="/Accessibility">
            {intl.formatMessage({
              id: "button.here",
            })}
          </LinkButton>
          <Typography>
            {intl.formatMessage({
              id: "services.info.15",
            })}
          </Typography>
        </RightColumn>
      </Section>
    </>
  );
};
