import { FC } from "react";
import { ServicesHero } from "./ServicesHero/ServicesHero";
import { ServicesInfo } from "./ServicesInfo/ServicesInfo";

export const Services: FC = () => {
  return (
    <>
      <ServicesHero />
      <ServicesInfo />
    </>
  );
};
