import { styled } from "@mui/material";
import Mountains from "../../Images/peaks_double.svg";

export const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  backgroundImage: "url(" + Mountains + ")",
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  color: "white",
  flexDirection: "column",
  flexGrow: 1,
}));
