import { Grid } from "@mui/material";
import { FC } from "react";
import { StartHero } from "./StartHero/StartHero";
import { StartInfo } from "./StartInfo/StartInfo";

export const Start: FC = () => {
  return (
    <Grid display="flex" flexDirection="column" flexGrow={1}>
      <StartHero />
      <StartInfo />
    </Grid>
  );
};
