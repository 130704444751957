import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Container } from "../../Components/Container/Container";
import { Root } from "./About.styles";

export const About: FC = () => {
  const intl = useIntl();
  return (
    <Root>
      <Container>
        <Grid item>
          <Typography variant="h1">
            {intl.formatMessage({ id: "about.title" })}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({ id: "about.subtitle" })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: "about.description.1" })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: "about.description.2" })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: "about.description.3" })}
          </Typography>
        </Grid>
      </Container>
    </Root>
  );
};
