import { FC } from "react";
import { TextSection } from "./Products.styles";

interface IProps {
  children: any;
}

export const TextColumn: FC<IProps> = (props) => {
  return (
    <TextSection item xs={12} md={6} xl={4}>
      {props.children}
    </TextSection>
  );
};
