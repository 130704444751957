import { Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import Linkedin from "../../Images/linkedin.svg";
import Simpledoc from "../../Images/simpledoc_blue_logo.svg";
import { LeftColumn } from "../Columns/LeftColumn";
import { RightColumn } from "../Columns/RightColumn";
import { Section } from "../Section/Section";
import {
  ExternalLink,
  LinkContainer,
  LinkedinLogo,
  SimpledocLogo,
  TitleRow,
} from "./Footer.styles";

export const Footer: FC = () => {
  const intl = useIntl();
  return (
    <Section blackBackground>
      <LeftColumn>
        <TitleRow>
          <SimpledocLogo src={Simpledoc} alt="Simpledoc logo" />
        </TitleRow>
        <div>
          <Typography>Kungsportsplatsen 1</Typography>
          <Typography>
            {intl.formatMessage({ id: "contact.postaddress" })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: "contact.country" })}
          </Typography>
          <LinkContainer>
            <ExternalLink href="tel:0105855500">010-585 55 00</ExternalLink>
            <ExternalLink href="mailto:info@simpledoc.com">
              info@simpledoc.com
            </ExternalLink>
          </LinkContainer>
        </div>
      </LeftColumn>
      <RightColumn justify="flex-end" align="flex-end">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/simpledoc/"
        >
          <LinkedinLogo src={Linkedin} alt="Linkedin logo" />
        </a>
      </RightColumn>
    </Section>
  );
};
