import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import {
  MobileHeaderCell,
  StyledMobileTable,
  StyledTable,
} from "./Accessibility.styles";

export const AccessibilityTable: FC = () => {
  const intl = useIntl();
  const headerDataDesktop: any[] = [
    {
      title: intl.formatMessage({
        id: "table.header.1",
      }),
    },
    {
      title: intl.formatMessage({
        id: "table.header.2",
      }),
    },
    {
      title: intl.formatMessage({
        id: "table.header.3",
      }),
    },
    {
      title: intl.formatMessage({
        id: "table.header.4",
      }),
    },
    {
      title: intl.formatMessage({
        id: "table.header.5",
      }),
    },
    {
      title: intl.formatMessage({
        id: "table.header.6",
      }),
    },
  ];

  const bodyDataDesktop: any[] = [
    {
      firstCell: intl.formatMessage({
        id: "table.firstRow.1",
      }),
      secondCell: intl.formatMessage({
        id: "table.firstRow.2",
      }),
      thirdCell: intl.formatMessage({
        id: "table.firstRow.3",
      }),
      fourthCell: intl.formatMessage({
        id: "table.firstRow.4",
      }),
      fifthCell: intl.formatMessage({
        id: "table.firstRow.5",
      }),
      sixthCell: intl.formatMessage({
        id: "table.firstRow.6",
      }),
    },
    {
      firstCell: intl.formatMessage({
        id: "table.secondRow.1",
      }),
      secondCell: intl.formatMessage({
        id: "table.secondRow.2",
      }),
      thirdCell: intl.formatMessage({
        id: "table.secondRow.3",
      }),
      fourthCell: intl.formatMessage({
        id: "table.secondRow.4",
      }),
      fifthCell: intl.formatMessage({
        id: "table.secondRow.5",
      }),
      sixthCell: intl.formatMessage({
        id: "table.secondRow.6",
      }),
    },
    {
      firstCell: intl.formatMessage({
        id: "table.thirdRow.1",
      }),
      secondCell: intl.formatMessage({
        id: "table.thirdRow.2",
      }),
      thirdCell: intl.formatMessage({
        id: "table.thirdRow.3",
      }),
      fourthCell: intl.formatMessage({
        id: "table.thirdRow.4",
      }),
      fifthCell: intl.formatMessage({
        id: "table.thirdRow.5",
      }),
      sixthCell: intl.formatMessage({
        id: "table.thirdRow.6",
      }),
    },
  ];

  const tableMobile: any[] = [
    {
      header: intl.formatMessage({
        id: "table.firstRow.1",
      }),
      firstCell: intl.formatMessage({
        id: "table.firstRow.2",
      }),
      secondCell: intl.formatMessage({
        id: "table.firstRow.3",
      }),
      thirdCell: intl.formatMessage({
        id: "table.firstRow.4",
      }),
      fourthCell: intl.formatMessage({
        id: "table.firstRow.5",
      }),
      fifthCell: intl.formatMessage({
        id: "table.firstRow.6",
      }),
    },
    {
      header: intl.formatMessage({
        id: "table.secondRow.1",
      }),
      firstCell: intl.formatMessage({
        id: "table.secondRow.2",
      }),
      secondCell: intl.formatMessage({
        id: "table.secondRow.3",
      }),
      thirdCell: intl.formatMessage({
        id: "table.secondRow.4",
      }),
      fourthCell: intl.formatMessage({
        id: "table.secondRow.5",
      }),
      fifthCell: intl.formatMessage({
        id: "table.secondRow.6",
      }),
    },
    {
      header: intl.formatMessage({
        id: "table.thirdRow.1",
      }),
      firstCell: intl.formatMessage({
        id: "table.thirdRow.2",
      }),
      secondCell: intl.formatMessage({
        id: "table.thirdRow.3",
      }),
      thirdCell: intl.formatMessage({
        id: "table.thirdRow.4",
      }),
      fourthCell: intl.formatMessage({
        id: "table.thirdRow.5",
      }),
      fifthCell: intl.formatMessage({
        id: "table.thirdRow.6",
      }),
    },
  ];

  const tableMobileTitles: any[] = [
    intl.formatMessage({
      id: "table.header.2",
    }),
    intl.formatMessage({
      id: "table.header.3",
    }),
    intl.formatMessage({
      id: "table.header.4",
    }),
    intl.formatMessage({
      id: "table.header.5",
    }),
    intl.formatMessage({
      id: "table.header.6",
    }),
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      {!isMobile && (
        <StyledTable>
          <TableHead>
            <TableRow>
              {headerDataDesktop.map((x) => (
                <TableCell>{x.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyDataDesktop.map((x) => (
              <TableRow>
                <TableCell>{x.firstCell}</TableCell>
                <TableCell>{x.secondCell}</TableCell>
                <TableCell>{x.thirdCell}</TableCell>
                <TableCell>{x.fourthCell}</TableCell>
                <TableCell>{x.fifthCell}</TableCell>
                <TableCell>{x.sixthCell}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      )}
      {isMobile && (
        <StyledMobileTable>
          {tableMobile.map((x) => (
            <>
              <TableHead>
                <TableRow>
                  <MobileHeaderCell position="left">
                    {x.header}
                  </MobileHeaderCell>
                  <MobileHeaderCell position="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{tableMobileTitles[0]}</TableCell>
                  <TableCell>{x.firstCell}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tableMobileTitles[1]}</TableCell>
                  <TableCell>{x.secondCell}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tableMobileTitles[2]}</TableCell>
                  <TableCell>{x.thirdCell}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tableMobileTitles[3]}</TableCell>
                  <TableCell>{x.fourthCell}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{tableMobileTitles[4]}</TableCell>
                  <TableCell>{x.fifthCell}</TableCell>
                </TableRow>
              </TableBody>
            </>
          ))}
        </StyledMobileTable>
      )}
    </>
  );
};
