import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import TemplateLogo from "../../../Images/template_logo.svg";
import TemplatePortal from "../../../Images/template_portal.png";

import { Section } from "../../../Components/Section/Section";
import { ExampleImage, Logo, TitleRow } from "../Products.styles";
import { ImageColumn } from "../ImageColumn";
import { TextColumn } from "../TextColumn";
export const TemplateContainer: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const intl = useIntl();
  return (
    <Section id="template" backgroundImage="RedBlob">
      {!isMobile && (
        <ImageColumn>
          <ExampleImage src={TemplatePortal} direction="vertical" />
        </ImageColumn>
      )}

      <TextColumn>
        <TitleRow>
          <Logo src={TemplateLogo} alt="Signature logo" />
          <Typography variant="h1" component="h2">
            Simpledoc Template
          </Typography>
        </TitleRow>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "products.template.title.1",
          })}
        </Typography>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: "products.template.description.1",
          })}
        </Typography>

        <Typography>
          {intl.formatMessage({
            id: "products.template.description.2",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.template.description.3",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.template.description.4",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.template.description.5",
          })}
        </Typography>
      </TextColumn>

      {isMobile && (
        <ImageColumn>
          <ExampleImage src={TemplatePortal} direction="vertical" />
        </ImageColumn>
      )}
    </Section>
  );
};
