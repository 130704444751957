import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Section } from "../../../Components/Section/Section";
import Signature365 from "../../../Images/signature_365.png";
import SignatureLogo from "../../../Images/signature_logo.svg";
import {
  Badge,
  ExampleImage,
  Logo,
  TextContainer,
  TitleRow,
} from "../Products.styles";
import { TextColumn } from "../TextColumn";
import { ImageColumn } from "../ImageColumn";
export const SignatureContainer: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Section id="signature" backgroundImage="YellowBlob">
      <TextColumn>
        <TitleRow>
          <Logo src={SignatureLogo} alt="Signature logo" />
          <TextContainer>
            <Typography variant="h1" component="h2">
              {intl.formatMessage({
                id: "products.signature.header",
              })}
            </Typography>
            <Badge>
              {intl.formatMessage({
                id: "start.topContainer.signatureCard.subheader",
              })}
            </Badge>
          </TextContainer>
        </TitleRow>
        <Typography variant="subtitle1">
          {intl.formatMessage({
            id: "products.signature.title.1",
          })}
        </Typography>
        <Typography fontWeight="bold">
          {intl.formatMessage({
            id: "products.signature.description.1",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.signature.description.2",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.signature.description.3",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "products.signature.description.4",
          })}
        </Typography>
      </TextColumn>
      <ImageColumn>
        <ExampleImage
          src={Signature365}
          alt="Signature logo"
          direction="horizontal"
          mobile={isMobile}
        />
      </ImageColumn>
    </Section>
  );
};
