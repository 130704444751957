import { Typography, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { ProductCards } from "../../../Components/ProductCards/ProductCards";
import { Root } from "./StartInfo.styles";
import { Section } from "../../../Components/Section/Section";
import theme from "../../../theme";

export const StartInfo: FC = () => {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <Root>
        <Typography variant="h1">
          {intl.formatMessage({
            id: "start.middleContainer.title",
          })}
        </Typography>
        <ProductCards />
      </Root>
      <Section
        backgroundImage="Mountains"
        bgSize={isLargeScreen ? "50%" : "75%"}
      >
        {""}
      </Section>
    </>
  );
};
