import { Grid, styled } from "@mui/material";
import Mountains from "../../../Images/mountains.svg";
import { Link } from "react-router-dom";

interface IRoot {
  blueBackground?: boolean;
  mountains?: boolean;
}
export const Root = styled(Grid, {
  shouldForwardProp: (prop) =>
    !["blueBackground", "mountains"].some((x) => x === prop),
})<IRoot>(({ blueBackground, mountains, theme }) => ({
  [theme.breakpoints.up("md")]: {
    flexWrap: "nowrap",
    gap: "2rem",
  },
  ...(blueBackground && {
    background: theme.palette.primary.light,
  }),
  ...(mountains && {
    backgroundImage: "url(" + Mountains + ")",
    backgroundPosition: "right bottom",
    backgroundRepeat: "no-repeat",
    backgroundSize: "75%",
  }),
}));

export const LeftColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const RightColumn = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

export const Logo = styled("img")(({ theme }) => ({
  width: "80%",
}));

export const TextContainer = styled("div")(({ theme }) => ({
  whiteSpace: "pre-line",
}));

export const IconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  marginTop: "1rem",
  "& img:first-child": {
    width: "36px",
    [theme.breakpoints.up("lg")]: {
      width: "90px",
    },
  },
  "& img": {
    width: "40px",
    [theme.breakpoints.up("lg")]: {
      width: "100px",
    },
  },
}));

export const LinkButton = styled(Link)(({ theme }) => ({
  color: "black",
  fontFamily: "proxima-nova",
}));

export const TextBubble = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    backgroundColor: "#E9EFF3",
    padding: "32px 32px",
    borderRadius: "8px",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
}));

export const ComputerImage = styled("img")(({ theme }) => ({
  maxWidth: "350px",
}));
