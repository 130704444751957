import { Grid, styled } from "@mui/material";

export const ContainerRoot = styled(Grid)(({ theme }) => ({
  padding: "2rem 4rem",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 2rem",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "2rem 10rem",
  },
  "& .MuiGrid-item": {
    paddingLeft: 0,
  },
}));
