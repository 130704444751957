import { FC } from "react";
import { Left } from "./Columns.styles";

interface IProps {
  children: any;
  gap?: string;
  justify?: string;
}

export const LeftColumn: FC<IProps> = (props) => {
  return (
    <Left
      item
      xs={12}
      md={6}
      xl={4}
      gap={props.gap}
      justifyContent={props.justify}
    >
      {props.children}
    </Left>
  );
};
