import { FC } from "react";
import { Root } from "./HeroCard.styles";

interface IProps {
  children: any;
  maxWidth?: string;
  marginLeftAuto?: boolean;
}

export const HeroCard: FC<IProps> = (props) => {
  return (
    <Root maxWidth={props.maxWidth} marginLeftAuto={props.marginLeftAuto}>
      {props.children}
    </Root>
  );
};
