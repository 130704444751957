import { Typography, styled } from "@mui/material";

export const Header = styled(Typography)(({ theme }) => ({
  fontWeight: "normal",
  fontSize: "2.5rem",
  "& span": {
    color: "#2EBEE4",
    fontWeight: "bold",
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "normal",
  "& span": {
    fontWeight: "bold",
  },
}));
