import { Grid, styled } from "@mui/material";
import Mountains from "../../Images/peaks_double.svg";

export const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  backgroundImage: "url(" + Mountains + ")",
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  height: "auto",
  color: "white",
  flexGrow: 1,
  "& p": {
    color: "white",
  },
  "& > div": {
    [theme.breakpoints.only("xs")]: {
      gap: "2rem",
    },
  },
  "& a,button": {
    width: "fit-content",
    textDecoration: "none",
  },
}));

export const LeftColumn = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginBottom: "2rem",
  },
}));
