import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#0A74A0",
      dark: "#00527C",
      light: "#E9EFF3",
    },
    secondary: {
      main: "#19857b",
      dark: "#09cdda",
      light: "#09cdda",
    },
    error: { main: "#19857b" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1700,
    },
  },
  typography: {
    fontFamily: ["proxima-nova"].join(","),
    h1: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.25rem",
    },
    subtitle1: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#0A74A0",
          color: "white",
          "&:hover": {
            backgroundColor: "#26a6c9",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#00527C",
          boxShadow: "none",
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          border: "solid 1px #00527C",
          backgroundColor: "#00527C",
          color: "white",
        },
        body: {
          fontWeight: "normal",
          border: "solid 1px #E9EFF3",
        },
      },
    },
  },
});

export default theme;
