import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Root } from "./App.styles";
import { Footer } from "./Components/Footer/Footer";
import { Header } from "./Components/Header/Header";
import { About } from "./Pages/About/About";
import { Contact } from "./Pages/Contact/Contact";
import { Products } from "./Pages/Products/Products";
import { Start } from "./Pages/Start/Start";
import ScrollToTop from "./ScrollToTop";
import theme from "./theme";
import { Services } from "./Pages/Services/Services";
import { Accessibility } from "./Pages/Accessibility/Accessibility";
import { Signature365 } from "./Pages/Signature365/Signature365";

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<Start />} />
      <Route path="/products" element={<Products />} />
      <Route path="/services" element={<Services />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/signature365" element={<Signature365 />} />
    </Routes>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <ScrollToTop />
          <Root>
            <Header />
            <Pages />
            <Footer />
          </Root>
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
