import { FC } from "react";
import { useIntl } from "react-intl";
import {
  ContactButtonRoot,
  Content,
  OpenInNewIcon,
} from "./ContactButton.styles";

export const ContactButton: FC = () => {
  const intl = useIntl();

  return (
    <ContactButtonRoot
      href="https://us20.list-manage.com/contact-form?u=74975c590d9d693c51d171c5b&form_id=e34d99bae92257674b9a7041ec42f529"
      target="blank"
    >
      <Content variant="contained">
        {intl.formatMessage({ id: "button.contact" })}
        <OpenInNewIcon />
      </Content>
    </ContactButtonRoot>
  );
};
