import { FC } from "react";
import { ImageSection } from "./Products.styles";

interface IProps {
  children: any;
}

export const ImageColumn: FC<IProps> = (props) => {
  return (
    <ImageSection item xs={12} md={6} xl={4} justifyContent={"center"}>
      {props.children}
    </ImageSection>
  );
};
