import { FC } from "react";
import { Root } from "./HeroSection.styles";
import { useMediaQuery, useTheme } from "@mui/material";

interface IProps {
  children: any;
  pb?: string;
  bgPosition: string;
  bgSize?: string;
  transform?: string;
  alignStart?: boolean;
  backgroundImage?:
    | "Island"
    | "Peaks"
    | "HorizontalPeaks"
    | "Poly"
    | "PeaksHorizontal"
    | "PeaksFull"
    | "PeaksMobile"
    | "Waves";
}

export const HeroSection: FC<IProps> = (props) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Root
      container
      bgPosition={props.bgPosition}
      bgSize={props.bgSize}
      flexGrow={1}
      paddingBottom={props.pb}
      height={isLargeScreen ? "40rem" : "auto"}
      backgroundImage={props.backgroundImage}
      justifyContent={props.alignStart ? "flex-start" : "center"}
    >
      {props.children}
    </Root>
  );
};
