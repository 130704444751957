import { styled } from "@mui/material";

interface IRoot {
  maxWidth?: string;
  marginLeftAuto?: boolean;
}
export const Root = styled("div", {
  shouldForwardProp: (prop) =>
    !["maxWidth", "marginLeftAuto"].some((x) => x === prop),
})<IRoot>(({ maxWidth, marginLeftAuto, theme }) => ({
  backgroundColor: "rgb(0,82,124)",
  padding: "2rem",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  maxWidth: maxWidth,
  height: "fit-content",
  boxShadow: "10px 10px 50px 0px rgba(0,0,0,0.3)",
  ...(marginLeftAuto && {
    marginLeft: "auto",
  }),
}));
