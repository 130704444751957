import { OpenInNew } from "@mui/icons-material";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LeftColumn } from "../../Components/Columns/LeftColumn";
import { RightColumn } from "../../Components/Columns/RightColumn";
import { Section } from "../../Components/Section/Section";
import {
  ButtonContainer,
  ButtonText,
  InnerList,
  LinkButton,
  List,
  NumberList,
} from "./Accessibility.styles";
import { AccessibilityHero } from "./AccessibilityHero";
import { AccessibilityTable } from "./AccessibilityTable";
export const Accessibility: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isXLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <AccessibilityHero />
      <Section column gap="1rem" id="accessibility-table">
        <Grid
          xl={8}
          container
          flexDirection="column"
          marginLeft="auto"
          marginRight="auto"
        >
          <Typography variant="h2" mb={"1rem"}>
            {intl.formatMessage({ id: "table.header" })}
          </Typography>
          <AccessibilityTable />

          <Typography variant="h2" my={"1rem"}>
            {intl.formatMessage({ id: "accessibility.info.5" })}
          </Typography>
          <Grid>
            <Typography>
              {intl.formatMessage({
                id: "accessibility.first.bulletList.title",
              })}
            </Typography>
            <List>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.first.bulletList.1",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.first.bulletList.2",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.first.bulletList.3",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.first.bulletList.4",
                  })}
                </Typography>
              </li>
            </List>
          </Grid>
        </Grid>
      </Section>
      <Section
        blackBackground
        backgroundImage={!isMobile ? "DevicesCircle" : undefined}
        devicesCircle={!isMobile}
        bgPosition="75%"
        bgSize={isLargeScreen ? "10%" : "15%"}
        id="accessibility-law"
        justifyContent="flex-start"
      >
        <Grid
          xl={6}
          container
          justifyContent={isXLargeScreen ? "flex-end" : "flex-start"}
          pr={isXLargeScreen ? "2rem" : ""}
        >
          <Grid lg={6} xl={8}>
            <Typography variant="h2" mb={"1rem"}>
              {intl.formatMessage({ id: "accessibility.info.6" })}
            </Typography>
            <List>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.second.bulletList.1",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.second.bulletList.2",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.second.bulletList.3",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "accessibility.second.bulletList.4",
                  })}
                </Typography>
                <InnerList>
                  <li>
                    <FormattedMessage
                      id="accessibility.second.bulletList.4.1"
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="accessibility.second.bulletList.4.2"
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="accessibility.second.bulletList.4.3"
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="accessibility.second.bulletList.4.4"
                      values={{
                        b: (chunks) => <strong>{chunks}</strong>,
                      }}
                    />
                  </li>
                </InnerList>
              </li>
            </List>
          </Grid>
        </Grid>
      </Section>
      <Section
        backgroundImage={isMediumScreen ? "Island" : undefined}
        bgPosition="100% 90%"
        bgSize="40%"
        id="accessibility-arguments"
        justifyContent="flex-start"
      >
        <Grid
          xl={6}
          container
          justifyContent={isXLargeScreen ? "flex-end" : "flex-start"}
          pr={isXLargeScreen ? "2rem" : ""}
        >
          <Grid lg={6} xl={8}>
            <Typography variant="h2">
              {intl.formatMessage({ id: "accessibility.info.7" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.info.8" })}
            </Typography>
            <NumberList>
              <li>
                <FormattedMessage
                  id="accessibility.third.bulletList.1"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="accessibility.third.bulletList.2"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="accessibility.third.bulletList.3"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="accessibility.third.bulletList.4"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
              <li>
                <FormattedMessage
                  id="accessibility.third.bulletList.5"
                  values={{
                    b: (chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </li>
            </NumberList>
          </Grid>
        </Grid>
      </Section>

      <Section blueBackground id="accessibility-tips">
        <LeftColumn gap="1rem">
          <Grid>
            <Typography variant="h2">
              {intl.formatMessage({ id: "accessibility.info.10" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.info.11" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.1" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.1.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.2" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.2.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.3" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.3.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.4" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.4.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.5" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.5.1" })}
            </Typography>{" "}
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.6" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.6.1" })}
            </Typography>
          </Grid>
        </LeftColumn>
        <RightColumn gap="1rem">
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.7" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.7.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.8" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.8.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.9" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.9.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.10" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.10.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.11" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.11.1" })}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.tips.12" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.tips.12.1" })}
            </Typography>
          </Grid>
        </RightColumn>
      </Section>
      <Section
        backgroundImage="Mountains"
        bgSize={isLargeScreen ? "50%" : "75%"}
        id="accessibility-help"
      >
        <LeftColumn gap="1rem">
          <Typography variant="h2">
            {intl.formatMessage({ id: "accessibility.help.1" })}
          </Typography>
          <Grid>
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.help.2" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.help.3" })}
            </Typography>
          </Grid>
          <Grid>
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.help.4" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.help.5" })}
            </Typography>
          </Grid>
          <Grid>
            <Typography>
              {intl.formatMessage({ id: "accessibility.help.6" })}
            </Typography>
            <ul>
              <li>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.help.7" })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.help.8" })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.help.9" })}
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid>
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.help.10" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.help.11" })}
            </Typography>
          </Grid>
          <Grid>
            <Typography fontWeight="bold">
              {intl.formatMessage({ id: "accessibility.help.12" })}
            </Typography>
            <Typography>
              {intl.formatMessage({ id: "accessibility.help.13" })}
            </Typography>
          </Grid>
        </LeftColumn>
        <RightColumn>
          <Typography variant="h2" mb={1}>
            {intl.formatMessage({ id: "accessibility.info.11" })}
          </Typography>
          <ButtonContainer container flexDirection="column" gap={1}>
            <LinkButton href="https://webbriktlinjer.se/wcag/" target="blank">
              <ButtonText>
                <Typography variant="body2">
                  {intl.formatMessage({ id: "accessibility.link.1.1" })}
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.link.1.2" })}
                </Typography>
              </ButtonText>
              <OpenInNew />
            </LinkButton>
            <LinkButton
              href="https://rkrattsbaser.gov.se/sfst?bet=2018:1937"
              target="blank"
            >
              <ButtonText>
                <Typography variant="body2">
                  {intl.formatMessage({ id: "accessibility.link.2.1" })}
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.link.2.2" })}
                </Typography>
              </ButtonText>
              <OpenInNew />
            </LinkButton>
            <LinkButton
              href="https://rkrattsbaser.gov.se/sfst?bet=2009:600"
              target="blank"
            >
              <ButtonText>
                <Typography variant="body2">
                  {intl.formatMessage({ id: "accessibility.link.3.1" })}
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.link.3.2" })}
                </Typography>
              </ButtonText>
              <OpenInNew />
            </LinkButton>
            <LinkButton href="https://www.digg.se/" target="blank">
              <ButtonText>
                <Typography variant="body2">
                  {intl.formatMessage({ id: "accessibility.link.4.1" })}
                </Typography>
                <Typography>
                  {intl.formatMessage({ id: "accessibility.link.4.2" })}
                </Typography>
              </ButtonText>
              <OpenInNew />
            </LinkButton>
          </ButtonContainer>
        </RightColumn>
      </Section>
    </>
  );
};
