import {
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { FC } from "react";
import { useIntl } from "react-intl";
import SignatureLogo from "../../Images/signature_logo.svg";
import {
  Badge,
  CardContainer,
  CardRoot,
  List,
  Logo,
  LogoTitle,
} from "./SignatureCard.styles";

export const SignatureCard: FC = () => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLarge = useMediaQuery(theme.breakpoints.only("md"));

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <CardContainer>
        <CardRoot>
          <Grid
            container
            justifyContent={"space-between"}
            p={"1rem"}
            alignItems={isMobile || isLarge ? "left" : "center"}
            flexDirection={isMobile || isLarge ? "column-reverse" : "row"}
          >
            <LogoTitle>
              <Logo src={SignatureLogo} alt="Simpledoc logo" />
              <Typography ml={"1rem"} fontSize="1.5rem" fontWeight="bold">
                Signature 365
              </Typography>
            </LogoTitle>
            <Badge>
              {intl.formatMessage({
                id: "start.topContainer.signatureCard.subheader",
              })}
            </Badge>
          </Grid>

          <CardContent>
            <Typography>
              {intl.formatMessage({
                id: "start.topContainer.signatureCard.description.1",
              })}
            </Typography>
            <List>
              <li>
                {" "}
                <Typography>
                  {intl.formatMessage({
                    id: "start.topContainer.signatureCard.description.2",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "start.topContainer.signatureCard.description.3",
                  })}
                </Typography>
              </li>
              <li>
                <Typography>
                  {intl.formatMessage({
                    id: "start.topContainer.signatureCard.description.4",
                  })}
                </Typography>
              </li>
            </List>
          </CardContent>
        </CardRoot>
      </CardContainer>
    </motion.div>
  );
};
