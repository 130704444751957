import { Button, Typography } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { LeftColumn } from "../../Components/Columns/LeftColumn";
import { Container } from "../../Components/Container/Container";
import { Root } from "./Contact.styles";

export const Contact: FC = () => {
  const intl = useIntl();
  return (
    <Root>
      <Container>
        <LeftColumn gap="1rem">
          <Typography variant="h1">
            {intl.formatMessage({
              id: "contact.title.1",
            })}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({
              id: "contact.description.1",
            })}
          </Typography>
          <a
            href="https://us20.list-manage.com/contact-form?u=74975c590d9d693c51d171c5b&form_id=e34d99bae92257674b9a7041ec42f529"
            target="blank"
          >
            <Button variant="contained">
              {intl.formatMessage({
                id: "button.contact",
              })}
            </Button>
          </a>
          <div>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "contact.office",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "contact.address",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "contact.postaddress",
              })}
            </Typography>
            <Typography>
              {intl.formatMessage({
                id: "contact.country",
              })}
            </Typography>
          </div>
          <div>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "contact.phone",
              })}
            </Typography>
            <Typography>010 - 585 55 00</Typography>
          </div>
        </LeftColumn>
        {/*  <RightColumn gap="1rem">
          <Typography variant="h1">
            {intl.formatMessage({
              id: "contact.title.2",
            })}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({
              id: "contact.description.2",
            })}
          </Typography>
          <div>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "contact.phone",
              })}
            </Typography>
            <Typography>0775 - 33 30 20</Typography>
          </div>
          <div>
            <Typography variant="body2">
              {intl.formatMessage({
                id: "contact.email",
              })}
            </Typography>
            <Typography>support@simpledoc.com</Typography>
          </div>
          <Button variant="contained">
            {intl.formatMessage({
              id: "button.contactForm",
            })}
          </Button>
        </RightColumn> */}
      </Container>
    </Root>
  );
};
